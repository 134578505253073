﻿// A SUPPRIMER DES QUE POSSIBLE
// uTILE UNIQUEMENT CAR D'ANCIENNES LIB S'APPUIE SUR DES ELEMENTS SUPPRIMES
(function(a) {
    a.extend({
        browser: {
            msie: false
        }
    });

    a.fn.extend({
        andSelf: a.fn.addBack
    });
})(jQuery);
